import { useSelector, useDispatch } from 'react-redux';
import { Redux } from 'stores/interfaces';
import * as middleware from './middleware';
import { PosReducer } from './interfaces';
import useDialog from 'stores/dialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleSetFidelityData } from 'stores/fidelity/middleware';
import { BusinessUnitResponse } from 'pages/pos/Distributors/interfaces';
import useBasket from 'stores/basket';

export default function usePos() {
	const dispatch = useDispatch();
	const posStore = useSelector((stores: Redux) => stores.posReducer);

	const navigate = useNavigate();
	const location = useLocation();
	const { handleOpenDialog } = useDialog();

	const { handleInitializeBasket } = useBasket();

	const handleSetHasDistributor = (hasDistributor: boolean) => {
		dispatch(middleware.handleSetHasDistributor(hasDistributor));
	};

	const setPosRegisterData = (data: string) => {
		dispatch(middleware.handleManagePosRegisterData('SET', data));
	};

	const clearPosRegisterData = () => {
		dispatch(middleware.handleManagePosRegisterData('CLEAR'));
	};

	return {
		posStore,
		selectedPos: posStore.myPos.find(({ isSelected }) => isSelected),
		setPosRegisterData,
		clearPosRegisterData,
		handleSetMyPos: (pos: PosReducer['myPos']) => dispatch(middleware.handleSetMyPos(pos)),
		handleIncrementToMyPos: (pos: PosReducer['myPos']) => {
			const newPos = pos.filter(({ pointOfSaleId }) => !posStore.myPos.find((myPos) => myPos.pointOfSaleId === pointOfSaleId));

			return dispatch(middleware.handleSetMyPos([...posStore.myPos, ...newPos]));
		},
		handleSetPosLoading: (posId: PosReducer['loading']) => dispatch(middleware.handleSetPosLoading(posId)),
		handleCleanPos: () => dispatch(middleware.handleSetCleanPos()),
		handleClearSelectedPos: () => dispatch(middleware.handleClearSelectedPos()),
		handleSetPosDistributors: (distributors: Concrete<PosReducer['selectedPos']['distributors']>) =>
			dispatch(middleware.handleSetPosDistributors(distributors)),
		handleSelectPos: (posId: string, isDistributorsPage?: boolean, openSideBasket?: boolean) =>
			dispatch(
				middleware.handleSetSelectedPos(
					posId,
					handleSetFidelityData,
					handleInitializeBasket,
					handleOpenDialog,
					navigate,
					location,
					isDistributorsPage,
					openSideBasket,
				),
			),
		handleUpdateDistributor: (distributor: BusinessUnitResponse) =>
			dispatch(middleware.handleSetUpdateDistributor(distributor)),
		handleSetHasDistributor,
	};
}
