window.Redux = (filter?: string | string[]) => {
	const objectString = JSON.parse(localStorage.getItem('persist:@PHC') || '{}');

	const uniqueFilter = (() => {
		if (Array.isArray(filter) && filter.length === 1) return filter[0];
		if (typeof filter === 'string') return filter;
		return false;
	})();

	const parsedValues = Object.entries(objectString).reduce((acc, [key, value]) => {
		if (filter && !filter.includes(key)) return acc;

		acc[key] = JSON.parse(value as string || '{}');

		return acc;
	}, {} as Record<string, any>);

	return uniqueFilter ? parsedValues[uniqueFilter] : parsedValues;
}