import { Dispatch } from '@reduxjs/toolkit';
import * as Sentry from "@sentry/browser";
import { notify } from 'front-commons/ds';
import { storageKeys } from 'shared/storage';
import { getUserData, logout } from 'services/customer';
import {
	resetUserIsLoggingOut,
	set2FAExpiration,
	setCustomer,
	setLoading,
	setLogout,
	setProvider,
	setToken,
	setUpdatePartially,
	manageFirstAccess,
} from './actions';
import { CustomerDataResponse } from 'services/customer/interfaces';
import { clearFidelity } from 'stores/fidelity/actions';
import { clearCatalog } from 'stores/catalog/actions';
import { setCleanPos } from 'stores/pos/actions';
import { CustomerProvider } from './interfaces';
import { insiderDispatcher } from 'services/insider';
import { sendInfosToPartners } from './helpers';

export const handleSetLogout =
	(userIsLoggingOut?: boolean): any =>
		async (dispatch: Dispatch) => {
			try {
				await logout()
			} catch (error) {
				console.log(error)
			} finally {
				insiderDispatcher.clearUser();
				Sentry.setUser(null);
				dispatch(setLogout(userIsLoggingOut));
				dispatch(clearFidelity());
				dispatch(clearCatalog());
				dispatch(setCleanPos());
				localStorage.removeItem(storageKeys.PHARMACY);
				localStorage.removeItem(storageKeys.MY_PHARMACIES);
				localStorage.removeItem(storageKeys.BASKET_END_DATE);
				localStorage.removeItem(storageKeys.ALERT_SETTINGS);
			}


		};

export const handleSetLoading = (loading: boolean): any => {
	return (dispatch: Dispatch) => {
		dispatch(setLoading(loading));
	};
};

export const handleResetUserIsLoggingOut = (): any => {
	return (dispatch: Dispatch) => {
		dispatch(resetUserIsLoggingOut());
	};
};

export const handleGetCustomerData = (): any => {
	return async (dispatch: Dispatch) => {
		dispatch(setLoading(true));
		try {
			const response = await getUserData();

			sendInfosToPartners(response)

			return dispatch(setCustomer(response));
		} catch (error) {
			console.log(error)
			notify.negative({
				description: 'Não foi possível buscar informações do usuário! Por favor, faça login novamente!',
			});

			return dispatch(handleSetLogout());
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const handleSetToken = (token: string): any => {
	return (dispatch: Dispatch) => {
		dispatch(setToken(token));
	};
};

export const handleSetProvider = (provider: CustomerProvider): any => {
	return (dispatch: Dispatch) => {
		dispatch(setProvider(provider));
		if (provider === 'ZECA_COPILOTO') {
			dispatch(setCustomer({} as CustomerDataResponse));
			dispatch(setCleanPos());
			dispatch(clearFidelity());
		}
	};
};

export const handleUpdatePartially = (customerData: Partial<CustomerDataResponse>): any => {
	return (dispatch: Dispatch) => {
		dispatch(setUpdatePartially(customerData));
	};
};

export const handleUpdate2FAExpiration = (timestamp: number): any => {
	return (dispatch: Dispatch) => {
		dispatch(set2FAExpiration(timestamp));
	};
};

export const handleManageFirstAccess = (action: 'ADD' | 'REMOVE' | 'CLEAR', item?: string): any => {
	return (dispatch: Dispatch) => {
		dispatch(manageFirstAccess(action, item));
	};
};
