import { Dropdown } from 'front-commons/ds';
import { useOverlay } from 'front-commons/hooks';
import { useState } from 'react';
import usePos from 'stores/pos';
import Trigger from './components/Trigger';
import Content from './components/Content';
import useCustomer from 'stores/customer';

export default function PosSelector() {
	const [expanded, setExpanded] = useState(false);

	const { OverlayComponent } = useOverlay({ defaultShowOverlay: expanded, topGap: 64 });
	const { posStore, selectedPos, handleSelectPos } = usePos();
	const { simulatedViewType, mainCustomerPage } = useCustomer();

	const isSimulated = !!simulatedViewType();
	const storedPosList = posStore.myPos.filter(({ isSelected }) => !isSelected).slice(0, 4);
	if (storedPosList.length < 4 && selectedPos) storedPosList.unshift(selectedPos);

	const handleChangePos = async (posId: string) => {
		document.body.style.pointerEvents = 'auto';
		await handleSelectPos(posId);
		setExpanded(false);
	};

	return (
		<>
			<Dropdown
				align="start"
				side="bottom"
				onOpenChange={() => setExpanded((prevState) => !prevState)}
				expanded={expanded}
				useExpanded
				offset={4}
				trigger-data-testid="pharmacy-selector-trigger"
				radiusFullDesktop
				triggerContent={
					<Trigger
						cnpj={selectedPos?.cnpj || ''}
						name={selectedPos?.tradeName || ''}
						loading={!!posStore.loading}
						expanded={expanded}
					/>
				}
			>
				<Content
					isSimulated={isSimulated}
					storedPosList={storedPosList}
					selectedPosId={selectedPos?.pointOfSaleId || ''}
					handleChangePos={handleChangePos}
					mainCustomerPage={mainCustomerPage}
					hasMoreThanFourPos={posStore.myPos.length > 4}
					disablePosSelecting={!!posStore.loading}
				/>
			</Dropdown>
			<OverlayComponent />
		</>
	);
}
